/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import moment from 'moment-mini';
import Account from '../../managers/Account';
import AnnouncementsManager from '../../managers/Announcements';

function BrandWidget({ creatorId }) {
  const [announcements, setAnnouncements] = useState([]);

  const getAnnouncements = async () => {
    const data = await AnnouncementsManager.getAnnouncementsByBrand(creatorId, Account.getToken());
    // TODO: Calculate how many are in the announcements array
    if (data && data.announcements) {
      setAnnouncements(data.announcements);
    }
    console.log(data.announcements);
  };

  useEffect(() => {
    console.log('=== Get announcements from server ===');
    console.log(creatorId);
    getAnnouncements();
  }, []);

  return (
    <div className="brand-widget">
      <div className="brand-widget__inner">
        <div className="brand-widget__logo">
          <img src={process.env.NAV_LOGO} width={process.env.NAV_LOGO_WIDTH} alt={process.env.SITE_NAME} />
        </div>
        <div className="brand-widget__description">{process.env.SITE_DESCRIPTION}</div>
        <a href="#" className="brand-widget__button">Reach out</a>
        {announcements.length > 0
        && (
        <div className="brand-widget__updates">
          <div className="brand-widget__updates--header">
            Updates
          </div>
          <div className="brand-widget__updates--container">
            {announcements.map((announcement) => (
              <div key={announcement.announcementId} className="brand-widget__updates--item">
                {announcement.textContent}
                <div className="brand-widget__updates--date">
                  {moment(announcement.dateCreated).format('MMM DD, YYYY')}
                </div>
              </div>
            ))}
          </div>
          
          <Link to="/account/collection" className="brand-widget__button">View more</Link>
        </div>
        )}
      </div>
    </div>
  );
}

export default BrandWidget;
