/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { toast } from 'react-toastify';
import Slider from 'react-slick';
import moment from 'moment-mini';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import AnnouncementsManager from '../../managers/Announcements';
import Account from '../../managers/Account';
import Throbber from '../throbber';

function Announcements({ creatorId }) {
  // State variables using useState
  const [announcementsLength, setAnnouncementsLength] = useState(0);
  const [announcements, setAnnouncements] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(3);
  const [isLoading, setIsLoading] = useState(true);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: itemsToShow,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 920,
        settings: {
          slidesToShow: itemsToShow === 1 ? 1 : 2,
        },
      },
      {
        breakpoint: 620,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const getAnnouncements = async () => {
    const data = await AnnouncementsManager.getAnnouncementsByBrand(creatorId, Account.getToken());
    // TODO: Calculate how many are in the announcements array
    if (data && data.announcements) {
      if (data.announcements.length === 1) {
        setItemsToShow(1);
      } else if (data.announcements.length === 2) {
        setItemsToShow(2);
      } else {
        setItemsToShow(3);
      }
      setAnnouncementsLength(data.announcements.length);
      setAnnouncements(data.announcements);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      toast.error('Error fetching announcements');
    }
  };

  useEffect(() => {
    getAnnouncements();
  }, []);

  return (
    <div>
      {announcementsLength > 0
    && (
    <Slider {...settings}>
      {announcements.map((announcement) => (
        <div key={announcement.announcementId} className="announcement-tile">
          <div className="announcement-tile__icon">
            <div className="icon-round">
              <img className="icon-round__image" src={process.env.MINI_LOGO} alt="icon" />
            </div>
          </div>
          <div className="announcement-tile__inner">
            <div className="announcement-description__date">
              {moment(announcement.dateCreated).format('MMM DD, YYYY')}
            </div>
            <div className="announcement-title">{process.env.SITE_NAME}</div>
            <div className="announcement-description">
              {announcement.textContent}
            </div>
          </div>
        </div>
      ))}
    </Slider>
    )}
      {isLoading
      && (
      <div className="announcement-tile">
        <div className="announcement-tile__inner ">
          <Throbber throbberText="Checking for updates..." />
        </div>
      </div>
      )}
    </div>
  );
}

export default Announcements;
